<template>
  <div class="login-user">
    <div class="d-flex justify-center">
      <v-card outlined tile>
        <v-card-title> {{ $t('login.title')}} </v-card-title>
        <v-card-text>{{ $t('login.enterCode')}}</v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
              v-model="pin"
                :label="$t('login.placeholder')"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                primary
                depressed
                x-large
                rounded
                color="primary"
                @click="login"
              >
                {{ $t('login.login')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <br />
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.login-user {
  width: 100%;
}
</style>

<script>

export default {
    data() {
        return {
            pin: "",
            orgId: '',
            reportId: ''
        }
    },
    methods: {
        login: async function() {
            var url = process.env.VUE_APP_VIHJAA_ENDPOINT + "/incident/authenticate"
            var data = {
                orgId: this.orgId,
                reportId: this.reportId,
                pin: this.pin
            }
            await this.axios.post(url, data).then(r => {
                this.$store.commit("addReport", r.data.item)
                this.$store.commit("authenticate")
                this.$router.push(`/login/tarkastele`)
            }).catch(e => console.log(e))
        }
    },
    mounted() {
        this.orgId = this.$route.query.org
        this.reportId = this.$route.query.id
    }
}
</script>